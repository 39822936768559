<template id="">
  <div>
    <div class="login usr">
      <!--h3><img class="logo" src="@/img/logo.png" alt="インテリジェントヘルスケア ストレスチェックApp"></h3-->
      <h3>ストレスチェック結果一覧</h3>
      <div v-if="ret_data != null">
        <table v-if="ret_data['sc_data'].length > 0" style="margin: auto">
          <tr v-for="(item, index) in ret_data['sc_data']" :key="item">
            <td>
              <a :id="index" @click="get_personal_pdf(item.sc_id)">{{
                item.sc_str
              }}</a>
            </td>
          </tr>
        </table>
        <p class="large cntr fwb" v-else>ストレスチェック受診歴はありません</p>
        <button
          class="uk-button uk-button-secondary uk-width-1-1"
          type="button"
          @click="$router.back()"
        >
          &lt;&nbsp;戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vComId: this.$store.state.common.sComId,
      vEmpCode: this.$store.state.common.sUserCode,
      alias_id: sessionStorage.getItem("storageAliasId"),
      email: sessionStorage.getItem("storageId"),
      pw: sessionStorage.getItem("storagePw"),
      ret_data: null,
    };
  },
  computed: {},
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック結果一覧";
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    this.$store.commit("common/doStartServerProcess");
    this.get_results();
    this.$store.commit("common/doEndServerProcess");
  },
  methods: {
    create_pdf_url(base64Str) {
      const file = new Blob([atob(base64Str)], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    get_results() {
      let createHash = require("sha256-uint8array").createHash;
      this.pw = createHash().update(this.pw).digest("hex");
      this.$axios
        .post("/get_personal_sc_history", {
          alias_id: this.alias_id,
          email: this.email,
          pw: this.pw,
        })
        .then((response) => {
          if (response.data.flg == true) {
            this.ret_data = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_personal_pdf(sc_id) {
      this.$axios
        .post("/get_personal_pdf", {
          emp_code: this.vEmpCode,
          sc_id: sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            window.open(
              window.URL.createObjectURL(this.make_blob(response.data.pdf))
            );
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    make_blob(base64_str) {
      let dataURI = "data:application/pdf;base64," + base64_str;
      // "iVBORw..."をバイナリに変換
      var byteString = atob(dataURI.split(",")[1]);

      // "image/png"
      var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

      // バイナリからBlobを作成
      for (
        var i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([content], {
        type: mimeType,
      });
      return blob;
      // window.open(window.URL.createObjectURL( blob )) ;
    },
  },
};
</script>
